export default class ExtensionLoader {


    /**  @type {Object}  */
    extensions = null;
    /**  @type {Object}  */
    global = null;
    /**  @type {function}  */
    callback;

    /**
     *
     * @param {Naja} naja
     * @param {Object} extensions
     * @param {object} global
     */
    constructor(naja, extensions, global) {
        this.naja = naja;
        this.extensions = extensions;
        this.global = global;
        this.callback = this.validate.bind(this);
        this.naja.addEventListener('init', this.init.bind(this));
        this.naja.snippetHandler.addEventListener('afterUpdate', this.callback);
    }

    init() {
        this.validate({snippet: document.body});
    }

    validate({snippet}) {
        let empty = true;
        for (var key in this.extensions) {
            if (this.extensions.hasOwnProperty(key)) {
                empty = false;
                if (snippet.querySelector(key)) {
                    let value = this.extensions[key];
                    delete this.extensions[key];
                    value().then(this.load.bind(this, key));
                }
            }
        }
        if (empty) {
            this.naja.snippetHandler.removeEventListener('afterUpdate', this.callback);
        }
    }

    load(selector, {default: Extension}) {
        this.naja.registerExtension(Extension, new Extension(this.naja, this.global, selector));
    }
}
