export default class DragCheck {

	/**
	 * @type {function}
	 */
	onMouseUp;

	/**
	 * @type {function}
	 */
	onMouseDown;

	/**
	 * @type {function}
	 */
	onMouseOver;

	/**
	 * @type {Element}
	 */
	container = null;

	/**
	 * @type {string}
	 */
	selector;
	/**
	 *
	 * @param {Element} container
	 * @param {string|null} selector
	 */
	constructor(container, selector = null) {
		this.selector = selector;
		this.container = container;
		this.onMouseUp = this.mouseUp.bind(this);
		this.onMouseDown = this.mouseDown.bind(this);
		this.onMouseOver = this.mouseOver.bind(this);
		container.addEventListener('mousedown', this.onMouseDown);
	}

	destroy() {
		this.container.removeEventListener('mousedown', this.onMouseDown);

	}

	mouseUp() {
		this.container.removeEventListener('mouseup', this.onMouseUp);
		this.container.removeEventListener('mouseover', this.onMouseOver);
		this.checked = null;
	}

	mouseDown(event, checkbox) {
		if (checkbox = this.findCheckbox(event.target)) {
			this.container.addEventListener('mouseup', this.onMouseUp);
			event.preventDefault();
			this.checked = !checkbox.checked;
			let mouseLeave = ()=> {
				if (this.checked !== null) {
					checkbox.checked = this.checked;
					this.container.addEventListener('mouseover', this.onMouseOver);
				}
			};
			event.target.addEventListener('mouseleave', mouseLeave, {one: true});
		}
	}

	mouseOver(event, checkbox) {
		if (checkbox = this.findCheckbox(event.target)) {
			checkbox.checked = this.checked;
		}
	}

	findCheckbox(el) {
		if (el.type && ['checkbox', 'radio'].indexOf(el.type) !== -1) {
			return el;
		}
		if (el.htmlFor && (el = document.getElementById(el.htmlFor))) {
			return this.findCheckbox(el);
		}
		if (this.selector && (el = el.closest(this.selector))) {
			return el.querySelector('input[type=checkbox],input[type=radio]');
		}
		return null;
	}
};