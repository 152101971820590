import Swiper from 'swiper/src/components/core/core-class';
// Core Modules
import Device from 'swiper/src/modules/device/device';
import Support from 'swiper/src/modules/support/support';
import Browser from 'swiper/src/modules/browser/browser';
import Resize from 'swiper/src/modules/resize/resize';
import Observer from 'swiper/src/modules/observer/observer';
//Components
import Autoplay from 'swiper/src/components/autoplay/autoplay';
import Keyboard from 'swiper/src/components/keyboard/keyboard';
import Pagination from 'swiper/src/components/pagination/pagination';
import Thumbs from 'swiper/src/components/thumbs/thumbs';
import Zoom from 'swiper/src/components/zoom/zoom';
import Navigation from 'swiper/src/components/navigation/navigation';
import Lazy from 'swiper/src/components/lazy/lazy';

const components = [
	Device,
	Support,
	Browser,
	Resize,
	Observer,
	Keyboard,
	Pagination,
	Thumbs,
	Zoom,
	Navigation,
	Autoplay,
	Lazy
];

if (typeof Swiper.use === 'undefined') {
	Swiper.use = Swiper.Class.use;
	Swiper.installModule = Swiper.Class.installModule;
}

Swiper.use(components);

export default Swiper;