import './scss/front.scss';

//import "core-js/stable";
//import "regenerator-runtime/runtime";


import 'custom-event-polyfill';
import 'common/js/closest.polyfill';
import 'core-js/features/promise/index';
import 'core-js/es/object/from-entries'

import '../common/common';
import 'common/js/forms.ext';
import './js/main';
import "utils/bootstrap-tookit/bootstrap.toolkit";