export default class EventFactory {
	/**
	 * @param {String} type
	 * @param {Boolean} bubbles
	 * @param {Boolean} cancelable
	 * @param {Boolean} once
	 * @returns {Event}
	 */
	static createEvent(type, bubbles = true, cancelable = true) {
		var event;
		if (typeof(Event) === 'function') {
			event = new Event(type, {bubbles, cancelable});
		} else {
			event = document.createEvent('Event');
			event.initEvent(type, bubbles, cancelable);
		}
		return event;
	}
}