/** @see https://mjau-mjau.com/blog/ajax-universal-analytics/ */
export default class GoogleAnalyticsExtension {
    constructor(naja) {
        if (window.gtag) {
            let script = document.querySelector('[data-google-measurement-id]');
            if(script){
                let measurementId = script.getAttribute('data-google-measurement-id');
                naja.addEventListener('success', () => {
                    window.gtag('config', measurementId);
                });
            }
        }
        if (window.ga) {
            naja.addEventListener('success', () => {
                window.ga('set', {page: window.location.pathname + window.location.search, title: document.title});
                window.ga('send', 'pageview');
            });
        }
    }
}