import $ from "jquery";

/**
 * Get time in ms
 * @license https://raw.github.com/jashkenas/underscore/master/LICENSE
 * @type {function}
 * @return {number}
 */
var getTime = Date.now || (() => new Date().getTime());

class Component {
	/**
	 * Generic constructor for all components
	 * @constructor
	 * @param {Element} el
	 * @param {Object} options
	 */
	constructor(classDef, el, options) {
		// Display error if el is valid HTML Element
		if (!(el instanceof Element)) {
			console.error(Error(el + ' is not an HTML Element'));
		}

		// If exists, destroy and reinitialize in child
		let ins = classDef.getInstance(el);
		if (!!ins) {
			ins.destroy();
		}

		this.el = el;
		this.$el = $(el);
	}

	/**
	 * Initializes components
	 * @param {class} classDef
	 * @param {Element | NodeList | jQuery} els
	 * @param {Object} options
	 */
	static init(classDef, els, options) {
		let instances = null;
		if (els instanceof Element) {
			instances = new classDef(els, options);
		} else if (!!els && (els.jquery || els.cash || els instanceof NodeList)) {
			let instancesArr = [];
			for (let i = 0; i < els.length; i++) {
				instancesArr.push(new classDef(els[i], options));
			}
			instances = instancesArr;
		}

		return instances;
	}
}
const M = {};
const _defaults = {
	responsiveThreshold: 0 // breakpoint for swipeable
};
/**
 * Multi browser support for document scroll top
 * @returns {Number}
 */
M.getDocumentScrollTop = function () {
	return window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
};
M.throttle = function (func, wait, options) {
	var context = void 0,
		args = void 0,
		result = void 0;
	var timeout = null;
	var previous = 0;
	options || (options = {});
	var later = function () {
		previous = options.leading === false ? 0 : getTime();
		timeout = null;
		result = func.apply(context, args);
		context = args = null;
	};
	return function () {
		var now = getTime();
		if (!previous && options.leading === false) previous = now;
		var remaining = wait - (now - previous);
		context = this;
		args = arguments;
		if (remaining <= 0) {
			clearTimeout(timeout);
			timeout = null;
			previous = now;
			result = func.apply(context, args);
			context = args = null;
		} else if (!timeout && options.trailing !== false) {
			timeout = setTimeout(later, remaining);
		}
		return result;
	};
};
export default class Parallax extends Component {
	static _parallaxes = [];

	constructor(el, options) {
		super(Parallax, el, options);

		this.el.M_Parallax = this;

		/**
		 * Options for the Parallax
		 * @member Parallax#options
		 * @prop {Number} responsiveThreshold
		 */
		this.options = $.extend({}, Parallax.defaults, options);
		this._enabled = window.innerWidth > this.options.responsiveThreshold;

		this.$img = this.$el.find('img').first();
		this.$img.each(function () {
			let el = this;
			if (el.complete) $(el).trigger('load');
		});


		this._updateParallax();
		this._setupEventHandlers();
		this._setupStyles();

		Parallax._parallaxes.push(this);
	}

	static get defaults() {
		return _defaults;
	}

	static init(els, options) {
		return super.init(this, els, options);
	}

	/**
	 * Get Instance
	 */
	static getInstance(el) {
		let domElem = !!el.jquery ? el[0] : el;
		return domElem.M_Parallax;
	}

	/**
	 * Teardown component
	 */
	destroy() {
		Parallax._parallaxes.splice(Parallax._parallaxes.indexOf(this), 1);
		this.$img[0].style.transform = '';
		this._removeEventHandlers();

		this.$el[0].M_Parallax = undefined;
	}

	static _handleScroll() {
		for (let i = 0; i < Parallax._parallaxes.length; i++) {
			let parallaxInstance = Parallax._parallaxes[i];
			parallaxInstance._updateParallax.call(parallaxInstance);
		}
	}

	static _handleWindowResize() {
		for (let i = 0; i < Parallax._parallaxes.length; i++) {
			let parallaxInstance = Parallax._parallaxes[i];
			parallaxInstance._enabled =
				window.innerWidth > parallaxInstance.options.responsiveThreshold;
			parallaxInstance._updateParallax.call(parallaxInstance);
		}
	}

	_setupEventHandlers() {
		this._handleImageLoadBound = this._handleImageLoad.bind(this);
		this.$el.on('load','img', this._handleImageLoadBound);
		this.$img[0].addEventListener('load', this._handleImageLoadBound);

		if (Parallax._parallaxes.length === 0) {
			Parallax._handleScrollThrottled = M.throttle(Parallax._handleScroll, 5);
			window.addEventListener('scroll', Parallax._handleScrollThrottled, {
				passive: true
			});

			Parallax._handleWindowResizeThrottled = M.throttle(Parallax._handleWindowResize, 5);
			window.addEventListener('resize', Parallax._handleWindowResizeThrottled, {
				passive: true
			});
		}
	}

	_removeEventHandlers() {
		this.$img[0].removeEventListener('load', this._handleImageLoadBound);

		if (Parallax._parallaxes.length === 0) {
			window.removeEventListener('scroll', Parallax._handleScrollThrottled, {
				passive: true
			});
			window.removeEventListener('resize', Parallax._handleWindowResizeThrottled, {
				passive: true
			});
		}
	}

	_setupStyles() {
		this.$img[0].style.opacity = 1;
	}

	_handleImageLoad() {
		this._updateParallax();
	}

	_updateParallax() {
		let containerHeight = this.$el.height() > 0 ? this.el.parentNode.offsetHeight : 500;
		let imgHeight = this.$img[0].offsetHeight;
		let parallaxDist = imgHeight - containerHeight;
		let bottom = this.$el.offset().top + containerHeight;
		let top = this.$el.offset().top;
		let scrollTop = M.getDocumentScrollTop();
		let windowHeight = window.innerHeight;
		let windowBottom = scrollTop + windowHeight;
		let percentScrolled = (windowBottom - top) / (containerHeight + windowHeight);
		let parallax = parallaxDist * percentScrolled;

		if (!this._enabled) {
			this.$img[0].style.transform = '';
		} else if (bottom > scrollTop && top < scrollTop + windowHeight) {
			this.$img[0].style.transform = `translate3D(-50%, ${parallax}px, 0)`;
		}
	}
}
