import Nette from 'nette-forms';
/**
 * Přidávání tříd bootstrapu při validaci
 */
export default class Status {
	static classes = {
		container: {
			submitting: 'before-submit',
			success: 'has-success',
			error: 'has-danger',
			warning: 'has-warning',
			info: 'has-info',
			validated: 'was-validated',
			// start: 'has-progress',
			// complete: 'has-progress',
			'start-ajax': 'has-progress',
		},
		input: {
			submitting: 'before-submit',
			success: 'is-valid',
			error: 'is-invalid',
			warning: 'has-warning',
			info: 'has-info',
			// start: 'has-progress',
			'start-ajax': 'has-progress',
		},
	};
	static namespace = 'nette.validation';
	static container = '.form-group';
	static error = 'error';
	static success = 'success';
	static reset = 'reset';
	static submitting = 'submitting';
	static complete = 'complete';
	static start = 'start';
	static ajax = 'ajax';
	static on = {
		'start-ajax': {
			add: ['start-ajax'],
		},
		'complete-ajax': {
			remove: ['start-ajax'],
		},
		submitting:{
			add: ['submitting'],
		},
		start: {
			add: ['start'],
		},
		complete: {
			remove: ['start','submitting'],
		},
		success: {
			remove: ['error'],
			add: ['success'],
		},
		error: {
			remove: ['success'],
			add: ['error'],
		},
		reset: {
			remove: ['success', 'error','submitting'],
		},
	};

	static getInputClasses() {
		return Status.classes.inputClasses || (Status.classes.inputClasses = Object.values(Status.classes.input));
	}

	static getContainerClasses() {
		return Status.classes.containerClasses || (Status.classes.containerClasses = Object.values(Status.classes.container));
	}

	static event(status) {
		if (Array.isArray(status)) {
			status = status.join('-');
		}
		return `nette-validation-${status}`;
	}

	static setStatus(element, status) {
		Status.clearStatus(element);
		Status.addStatus(element, status);
	}

	static addStatus(element, status) {
		/*let validationClass = element.getAttribute('data-validation-class') || element.closest('form').getAttribute('data-validation-class');
		if (validationClass != false) {
			Status.addClass(element, Status.classes.input[status]);
			Status.addClass(Status.getContainer(element), Status.classes.container[status]);
		}*/
		Status.classes.input[status] && Status.addClass(element, Status.classes.input[status]);
		Status.classes.container[status] && Status.addClass(Status.getContainer(element), Status.classes.container[status]);
	}

	static removeStatus(element, status) {
		Status.removeClass(element, Status.classes.input[status]);
		Status.removeClass(Status.getContainer(element), Status.classes.container[status]);
	}

	static addClass(element, cls) {
		if (element) {
			element.classList.add(cls);
		}
	}

	static removeClass(element, cls) {
		if (element) {
			element.classList.remove(cls);
		}
	}

	/**
	 * @param {Element} element
	 * @param {array} classes
	 */
	static removeClasses(element, classes) {
		if (element) {
			for (let cls of classes)element.classList.remove(cls);
		}
	}

	static clearStatus(element) {
		Status.removeClasses(element, Status.getInputClasses());
		Status.removeClasses(Status.getContainer(element), Status.getContainerClasses());
	}

	static apply(action, status, element) {
		if (element = element.form.elements[element.name]) {
			element = (element instanceof NodeList || element instanceof HTMLCollection) ? element : [element];
			for (let el of element)Status[`${action}Status`](el, status);
		}
	}

	/**
	 * @param {Element} element
	 */
	static getContainer(element) {
		return Nette.getContainer(element);
	}

	/**
	 *
	 * @param {string|string[]} status
	 * @param {HTMLInputElement|NodeList} element
	 */
	static trigger(status, element) {
		element.dispatchEvent(new CustomEvent(Status.event(status),{bubbles:true}));
	}

	/**
	 * Register jQuery plugins for all actions
	 * Add action listeners for events to document
	 */
	static install() {
		for (let [on,actions] of Object.entries(Status.on)) {
			const entries = Object.entries(actions);
			document.addEventListener(Status.event(on), (e)=> {
				for (let [action, operations] of entries) {
					for (let operation of operations) {
						Status.apply(action, operation, e.target);
					}
				}
			}, true);
		}
	}
}
