
export default class Cache {
	static instances = {};

	/**
	 *
	 * @type {Object.<string, Cache>}
	 */
	cache = {};

	/**
	 *
	 * @param {string} value
	 * @param {object} response
	 */
	add(value, response) {
		this.cache[value] = response;
	}

	/**
	 *
	 * @param {string} value
	 * @returns {object}
	 */
	get(value) {
		return this.cache[value];
	}

	static getInstance(element) {
		const name = `${window.location}-${element.form.id}-${element.name}`;
		if (!Cache.instances[name]) {
			Cache.instances[name] = new Cache();
		}
		return Cache.instances[name];
	}
}
