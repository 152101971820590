/**
 * BootstrapToolkit class
 */
class BootstrapToolkit {
    breakpointsMap = {};
	breakpoints = {};

    constructor() {
       BootstrapToolkit.onDocumentReady(this.init.bind(this));
    }

    init() {
        this.node = this.createElement('bs-breakpoint', true);
        let bpsNode = this.createElement(`bs-breakpoints`);
        let value = this.parseValue(bpsNode);
        bpsNode.parentElement.removeChild(bpsNode);
        for(let entry of value.trim().split(' ')){
			let [bp, width] = entry.split(':');
			this.breakpointsMap[bp] = Number(width.replace(/\D/g, ''));
		}
        this.breakpoints = Object.keys(this.breakpointsMap);
    };

    createElement(id, visible) {
        let node = document.createElement('div');
        node.id = id;
        visible || (node.className = 'd-none');
        document.body.appendChild(node);
        return node;
    }

    /**
     * Return string representation of current breakpoint.
     * @returns {string} xs,sm,md,lg,xl ...
     */
    getCurrentBreakpoint() {
        return this.parseValue(this.node);
    }

    parseValue(el) {
        return window.getComputedStyle(el, ':before').getPropertyValue('content').replace(/([\\'"])/g, '');
    }

    is(breakpoint) {
        return this.breakpointsMap[breakpoint] <= window.innerWidth; ///.indexOf(breakpoint) <= this.breakpoints.indexOf(this.getCurrentBreakpoint());
    }

    /**
     * @todo create version detection
     * @returns {number}
     */
    static detectVersion() {
        return 4;
    }
    static onDocumentReady = function(callback) {
        if (document.readyState !== 'loading') {
            callback.call(this);
        } else {
            document.addEventListener('DOMContentLoaded', callback);
        }
    };
}
window.BootstrapToolkit = new BootstrapToolkit();
export default window.BootstrapToolkit;