/** @type {Naja}*/
import Naja from "common/js/naja";
import Nette from "nette-forms";
import ExtensionLoader from "utils/ExtensionLoader";
import GoogleAnalyticsExtension from "./GoogleAnalyticsExtension";
import "utils/bootstrap-tookit/bootstrap.toolkit";
//import Swiper from "common/js/swiper";
import Swiper from "./swiper";
import Parallax from "common/js/jquery.parallax";
import Masonry from 'masonry-layout';
import "./bootstrap";
import DragCheck from "./DragCheck.class";
import Status from "../../common/js/forms.status";
/** @type {ExtensionLoader}*/
/** @type {Document}*/
const d = document;
/** @type {jQuery}*/
const $d = $(d);
/** @type {Window}*/
const w = window;
/** @type {jQuery}*/
const $w = $(w);
w.LOCALE = d.documentElement.lang;
w.LANG = LOCALE.split('_')[0];
w.$$ = (e) => d.querySelectorAll(e);
w.$ID = (id) => d.getElementById(id);
w.listen = 'addEventListener';
w.jQuery = $;

class ApplicationExtension {

    /**  @type {string|null} */
    lang = LANG;
    /** @type {HTMLElement|null} */
    navigation = null;

    afterUpdates = ['initSwiper', 'initParallax', 'afterUpdate', 'initMasonry', 'dropdownFocusHandler', 'productMasonry'];

    constructor(naja) {
        this.naja = naja;
        naja.addEventListener('init', this.init.bind(this));
        naja.addEventListener('success', this.success.bind(this));
        naja.addEventListener('before', this.before.bind(this));
        for (let methodName of this.afterUpdates) {
            const method = this[methodName];
            naja[listen]('load', tryCall(() => method.call(this, document.body)));
            //naja.snippetHandler[listen]('afterUpdate', tryCall(({snippet}) => method(snippet)));
        }
    }

    init() {
        let that = this;
        this.$navbarCollapse = $('#navbar-main-collapse');
        this.$navbarCollapse.on('shown.bs.collapse', this.toggleScrolling.bind(this, true));
        this.$navbarCollapse.on('hidden.bs.collapse', this.toggleScrolling.bind(this, false));
        this.navigation = d.querySelector('[data-navigation]');
        for (let method of this.afterUpdates) {
            this[method](document.body);  //Fix bootstrap tabů, které se nedají přepínat jelikož zůstává třída active u tlačítek.
        }
        $d.on('shown.bs.tab', '.variants-table', function (e) {
            if (e.target.dataset.gallery) {
                let $gallery = $(e.target.dataset.gallery);
                $gallery.siblings().removeClass('active');
                $gallery.addClass('active');
                $gallery.find('[data-swiper]').each((i, e) => {
                    e.swiper && e.swiper.destroy();
                    delete e.swiper;
                    that.initSwiper(e.parentElement);
                });
            }
            $(this).find(`[data-toggle=tab]:not([data-target="${e.target.dataset.target}"])`).removeClass('active');
        });
        $d.on('click', 'main', () => {
            $d.find('#navbar-secondary [data-toggle=dropdown]').dropdown('hide');
        });
    }

    toggleScrolling(on) {
        $(d.body).toggleClass('navbar-opened', on);
    }

    before() {
        BootstrapToolkit.is('sm') || this.$navbarCollapse.collapse("hide");
    }

    dropdownFocusHandler() {
        $('.dropdown-menu').find('a').on('focus', function () {
            let $menu = $(this).closest('.dropdown-menu');
            if ($menu.length && !$menu.hasClass('show')) {
                $('nav').find('.dropdown-menu.show').dropdown('hide');
                $menu.dropdown('show');
            }
        });

    }

    productMasonry() {
        /*for (let e of $$('[data-fit-bounds]')) {
         if (!e.fitBounds) {
         e.fitBounds = new FitBounds(e);
         }
         }*/

        let el = d.querySelector('[data-mansonry-products]');
        if (el && !el.msnry) {
            el.msnry = new Masonry(el, $.extend({
                horizontalOrder: false,
                percentPosition: true,
                initLayout: false,
                containerStyle: null,
                transitionDuration: 0
            }, $(el).data('mansonry-products') || {}));
            el.msnry.on('layoutComplete', function () {
                el.classList.add('layout-complete');
            });
            el.msnry.layout();
        }
    }

    success({response}) {
        if (response.scrollTop) {
            let el;
            if (window.location.hash && (el = document.querySelector(window.location.hash))) {
                let rect = el.getBoundingClientRect();
                window.scrollTo(0, Math.max(rect.top + window.pageYOffset - this.navigation.offsetHeight, 0));
            } else if (response.scrollTop === true) {
                window.scrollTo(0, 0);
            } else if ($.isNumeric(response.scrollTop)) {
                window.scrollTo(0, response.scrollTop);
            } else if (typeof response.scrollTop === 'string' && (el = document.querySelector(response.scrollTop))) {
                window.scrollTo(0, $(el).offset().top - 100);
            } else {
                window.scrollTo(0, 0);
            }
        }
    }

    initParallax(snippet) {
        $(snippet).find('.parallax').each((i, e) => {
            if (!e.parallax) {
                const parallax = e.parallax = new Parallax(e, {responsiveThreshold: 768});
                e[listen]('destroy', () => parallax.destroy());
            }
        });
    }

    replaceBreakpoints(options) {
        if (options && options.breakpoints) {
            for (let [bp, value] of Object.entries(options.breakpoints)) {
                if (BootstrapToolkit.breakpointsMap[bp]) {
                    options.breakpoints[BootstrapToolkit.breakpointsMap[bp]] = value;
                    delete options.breakpoints[bp];
                }
            }
        }
    }

    initSwiper(snippet) {
        $(snippet).find('[data-swiper]').each((i, el) => {
            if (!el.swiper) {
                const options = JSON.parse(el.getAttribute('data-swiper'));
                this.replaceBreakpoints(options);
                this.replaceBreakpoints(options.thumbs && options.thumbs.swiper);
                //cl(options);
                options.init = false;
                let swiper = el.swiper = new Swiper(el, options);
                if (options._initialSlide) {//Hot fix pro initialSlide
                    swiper.on('init', () => {
                        swiper.slideTo(options._initialSlide,0);
                    });
                }
                if (options.thumbs) {
                    swiper.on('init', () => {
                        let thumbsSwiper = swiper.thumbs.swiper;
                        if (thumbsSwiper) {
                            thumbsSwiper.visibleSlidesIndexes = [];
                            thumbsSwiper.update();
                        }
                    });
                }
                swiper.on('slideChangeTransitionStart', () => {
                    $(el).find('.parallax').each((i, e) => {
                        if (e.parallax) e.parallax._updateParallax.call(e.parallax);
                    });
                });
                swiper.init();
                el[listen]('destroy', () => swiper.destroy());
            }
            return el.swiper;
        });
    }


    /**
     * Checks if all images of pages are already loaded
     */
    initMasonry(snippet) {
        const elements = snippet.hasAttribute('data-masonry-grid') ? [snippet] : snippet.querySelectorAll('[data-masonry-grid]');
        for (let element of elements) {
            const masonry = element.masonry || (element.masonry = new Masonry(element, $.extend({
                initLayout: false,
                containerStyle: null,
                transitionDuration: 0
            }, $(element).data('masonry-grid'))));
            if (element.hasAttribute('data-masonry-history')) {
                masonry.on('layoutComplete', (laidOutItems) => {
                    let ly = 0;
                    for (let el of laidOutItems) {
                        if (ly && ly + 30 > el.position.y) {
                            el.element.classList.add('mt-5');
                        } else {
                            el.element.classList.remove('mt-5');
                        }
                        ly = el.position.y;
                        $(el.element).toggleClass('left', !el.position.x).toggleClass('right', !!el.position.x);
                    }
                });
            }
            const images = element.querySelectorAll('img');
            for (let img of images) {
                img.addEventListener('load', () => masonry.layout(), {one: true});
            }
            masonry.layout();
        }
    }


    /**
     * @param snippet
     */
    afterUpdate(snippet) {
        $(snippet).find('[data-toggle="popover"]').each((i, e) => {
            $(e).popover({
                container: e.closest('[id^=snippet-],.tab-pane')
            });
        });
        $(snippet).find('[data-iframe-src]').one('click', function () {
            this.querySelector('iframe').src = this.getAttribute('data-iframe-src');
            const swiper = this.closest('[data-swiper]');
            if (swiper) swiper.swiper.autoplay.stop();
        });
    }
}

w.addEventListener('mousewheel', (e) => {
    if ((e.type !== 'mousewheel' || e.deltaY) && BootstrapToolkit.is('sm')) {
        $d.find('#navbar-secondary [data-toggle=dropdown]').dropdown('hide');
    }
}, {passive: true});

class ScrollSpy {
    constructor(e) {
        this.e = e;
        if (e.dataset.scrollSpy) {
            if ($.isNumeric(e.dataset.scrollSpy)) {
                const offset = Number(e.dataset.scrollSpy);
                this.offset = () => offset;
            } else {
                const el = d.querySelector(e.dataset.scrollSpy);
                if (el) {
                    this.offset = () => el.offsetHeight;
                }
            }
        }
        if (!this.offset) {
            this.offset = () => 0;
        }
        $w.scroll(this.validate.bind(this));
    }

    validate() {
        return this.e.classList[this.offset() < w.scrollY ? 'add' : 'remove']('scrolled')
    }
}


// -- Nette Ajax---------------------------------------------------------------------------------------

// @formatter:off
const ext = {
    '[data-map]': () => import(/* webpackChunkName: "google-map" */'./GoogleMapExtension'),
    '[data-in-viewport]': () => import(/* webpackChunkName: "in-viewport" */'./IntersectionObserverExtension'),
    '[data-slider]': () => import(/* webpackChunkName: "slider" */'./SliderExtension'),
};


// @formatter:on
Naja.registerExtension(ApplicationExtension);
Naja.registerExtension(GoogleAnalyticsExtension);
Naja.registerExtension(ExtensionLoader, ext, {Nette, lang: LANG, locale: LOCALE});

Nette.onDocumentReady(()=>{

    document.body.DragCheck = new DragCheck(document.body, '.easy-checkbox');
    Naja.initialize();
    $d.on('hide.bs.collapse show.bs.collapse', '[data-cookie-toggle]', function (e) {
        d.cookie = `${this.dataset.cookieToggle}=${e.type === 'show'};path=/`;
    })
        //Nactení swiperu po otevření modalního okna
        .on('shown.bs.modal', '.modal', function (e) {
            $(this).find('[data-swiper]').each((i, el) => {
                el.swiper.update();
                if (el.swiper.thumbs) el.swiper.thumbs.swiper.update();
            });
        })
        //Ovládání swiperu pomocí kliknutí na náhledové obrázky
        .on('click', '[data-slide-to]', function (e) {
            $(this.getAttribute('href') || this.getAttribute('data-target')).find('[data-swiper]').each((i, el) => {
                el.swiper.slideTo(this.getAttribute('data-slide-to'), 0);
            });
        })
        .on('mouseover', '[data-toggle=tab][data-trigger=hover]', function () {
            $(this).tab('show');
        })
        .on('hide.bs.dropdown', '#navbar-secondary .nav-item', function (e) {
            if (e.clickEvent && (e.clickEvent.target.tagName !== 'A' || $(this).hasClass('no-hide'))) {
                e.stopPropagation();
                return false;
            }
        })
        .on('show.bs.collapse shown.bs.collapse', '#filter-collapse', function (e) {
            const el = this.querySelector('[data-mansonry-products]');
            if (el) {
                switch (e.type) {
                    case 'shown':
                        el.msnry.layout();
                        el.classList.add('layout-complete');
                        break;
                    case 'show':
                        el.classList.remove('layout-complete');
                }
            }
        }).on('show.bs.tab', '.variants-table', function (e) {
        for (let el of this.querySelectorAll('[data-src]')) {
            el.src = el.dataset.src;
            delete el.dataset.src;
        }
    });

    $('[data-scroll-spy]').each((i, e) => new ScrollSpy(e));
});