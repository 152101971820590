import Naja from "common/js/naja";
const
	modal = 'modal',
	show = 'show',
	before = 'before',
	after = 'after',
	hide = 'hide',
	snippet = 'snippet',
	namespace = 'nette',
	snippetBefore = `--${snippet}-${before}`,
	snippetAfter = `--${snippet}-${after}`,
	modalShow = `.${modal}.${show}`;

/**
 * Základní rozšíření ajaxové knihovny Naja
 * 1. Zobrazuje modální okna bootstrapu po načtení stránky nebo překreslení stránky
 * 2. Přidávání ke snipetům třídy before-update před překreslením nebo after-update po překreslení. Využitelné například pro přidání animací.
 * 3. Posílá události before-update.nette před překreslením nebo after-update.nette po překreslení.
 */
export default class AjaxExtension {

	/**
	 *
	 * @type {Naja}
	 */
	naja = null;

	init() {
		[this.lang] = document.documentElement.lang.split('_');
	}

	constructor(naja) {
		this.naja = naja;
		naja.addEventListener('init', this.init.bind(this));
		naja.snippetHandler.addEventListener('beforeUpdate', this.snippetBefore.bind(this));
		naja.snippetHandler.addEventListener('afterUpdate', this.snippetAfter.bind(this));
		naja.addEventListener('error', this.error.bind(this));
		naja.addEventListener('before', this.before.bind(this));
		naja.addEventListener('load', this.loadModals.bind(this));
		naja.addEventListener('interaction', this.interaction.bind(this));
	}


	makePost(url, data, {options, originalEvent}) {
		if (this.naja.uiHandler.isUrlAllowed(url)) {
			if (originalEvent) {
				originalEvent.preventDefault();
			}
			this.naja.makeRequest('POST', url, data, options);
		}
	}

	interaction(event) {
		const {element, options} = event;
		options.element = element;
		if (element.disabled) {
			event.preventDefault();
		}
	}

	/**
	 *
	 * @param {XMLHttpRequest} xhr
	 * @param options
	 */
	before({xhr, options:{element}}) {
		if (element && element.getAttribute('data-unique') === 'prevent') {
			element.disabled = true;
			xhr.addEventListener('loadend', ()=> {
				element.disabled = false;
			});
		}

	}

	loadModals() {
		$(modalShow).modal('show');
		$('[data-modal]').on('click',(event)=>{
			this.showContentModal(event.target.dataset.modal, event.target.title);
			event.preventDefault();
			return false;
		});
	}

	/**
	 *
	 * @param error
	 * @param {XMLHttpRequest} xhr
	 * @param {Object} response
	 * @param options
	 */
	error({xhr, response, options}) {
		if (xhr.status === 500 || (typeof response === 'string' && response.startsWith('<!DOCTYPE html>'))) {
			if (response) {
				this.showContentModal(response);
			} else if(!document.body.className.match('debug-mode')) {
				const newXhr = new XMLHttpRequest();
				newXhr.onreadystatechange = ()=> {
					this.showContentModal(newXhr.responseText);
				};
				newXhr.open("GET", '/500.' + this.lang + '.html', true);
				newXhr.send();
			}
		} else {
			this.naja.fireEvent('success', {xhr, response, options});
		}
	}

	showContentModal(content, title = null) {
		const modal = document.getElementById('content-modal');
		const modalBody = modal.querySelector('.modal-body');
		const modalTitle = modal.querySelector('.modal-title');
		modalBody.innerHTML = content;
		if (modalTitle) {
			if (title !== null) {
				modalTitle.innerHTML = title;
			} else {
				const _title = modalBody.querySelector('title');
				if (_title) {
					modalTitle.innerHTML = _title.innerText;
				}
			}
		}
		$(modal).modal('show');
	}

	/**
	 *
	 * @param {Element} snippet
	 */
	snippetBefore({snippet}) {
		$(snippet).find(modalShow).modal('hide');
		snippet.classList.add(snippetBefore);
		snippet.classList.remove(snippetAfter);
		snippet.dispatchEvent(new CustomEvent(`${snippetBefore}.${namespace}`,{
			bubbles: true,
			detail: {snippet}
		}));
	}

	/**
	 *
	 * @param {Element} snippet
	 */
	snippetAfter({snippet}) {
		snippet.classList.add(snippetAfter);
		snippet.classList.remove(snippetBefore);
		snippet.dispatchEvent(new CustomEvent(`${snippetAfter}.${namespace}`,{
			bubbles: true,
			detail: {snippet}
		}));
	}
}

Naja.registerExtension(AjaxExtension);
