export default class Button {
	static PLUGIN = 'btn';
	/**
	 * @type {Element}
	 */
	element = null;
	/**
	 * @type {Object}
	 */
	options = {
		loadingText: 'loading...',
	};
	/**
	 * @type {boolean}
	 */
	isLoading = false;

	constructor(element, options) {
		this.element = element;
		for (let key in element.dataset) {
			if (element.dataset.hasOwnProperty(key)) {
				this.options[key] = element.dataset[key];
			}
		}
		for (let key in options) {
			if (options.hasOwnProperty(key)) {
				this.options[key] = options[key];
			}
		}
		this.isLoading = false;
	}

	setState(state) {
		const d = 'disabled', el = this.element, val = el.matches('input,select') ? 'value' : 'innerHTML', data = el.dataset;

		state += 'Text';

		if (data.resetText == null) {
			data.resetText = el[val];
		}

		// push to event loop to allow forms to submit
		setTimeout(()=> {
			el[val] = data[state] == null ? this.options[state] : data[state];
			if (state === 'loadingText') {
				this.isLoading = true;
				el.classList.add(d);
				el.disabled = true;
			} else if (this.isLoading) {
				this.isLoading = false;
				el.classList.remove(d);
				el.disabled = false;
			}
		}, 0);
	}
}
