const Bootstrap = {};

//Bootstrap.Alert = require('bootstrap/js/dist/alert');
//Bootstrap.Button = require('bootstrap/js/dist/button');
//Bootstrap.Carousel = require('bootstrap/js/dist/carousel');
Bootstrap.Collapse = require('bootstrap/js/dist/collapse');
Bootstrap.Dropdown = require('bootstrap/js/dist/dropdown');
Bootstrap.Modal = require('bootstrap/js/dist/modal');
Bootstrap.Popover = require('bootstrap/js/dist/popover');
//Bootstrap.Scrollspy = require('bootstrap/js/dist/scrollspy');
Bootstrap.Tab = require('bootstrap/js/dist/tab');
//Bootstrap.Toast = require('bootstrap/js/dist/toast');
//Bootstrap.Tooltip = require('bootstrap/js/dist/tooltip');
Bootstrap.Util = require('bootstrap/js/dist/util');

export {Bootstrap};
export default Bootstrap;