import Naja from "common/js/naja";

/**
 * Plugin pro jQuery pro indikaci ajaxových dotazů.
 */
class AjaxIndicator {

	static PLUGIN = 'indicator';

	static DATA = `data-${AjaxIndicator.PLUGIN}`;

	static PROGRESS_CLASS = 'ajax-progress';

	static event = 'nette-naja-ajax';

	/**
	 * @type {Element}
	 */
	element = null;

	/**
	 * @type {Element}
	 */
	indicator = null;

	/**
	 * @type {Element}
	 */
	counter = null;

	/**
	 * @type {number}
	 */
	count = 0;

	decreaseCallback = this.decrease.bind(this);

	capture;

	constructor(element) {
		this.element = element;
		element.dataset.ajaxRequests = 0;
		const selector = element.dataset[AjaxIndicator.PLUGIN];
		const counterSelector = element.dataset[`${AjaxIndicator.PLUGIN}Counter`];
		this.capture = element.dataset[`${AjaxIndicator.PLUGIN}Capture`];
		//@todo: foreachovat querSelectAll
		this.indicators = [];
		if (selector) {
			for (let el of document.querySelectorAll(selector))this.indicators.push(el);
		} else {
			this.indicators.push(element);
		}
		if (counterSelector) {
			this.counter = document.querySelector(counterSelector);
		}
		element.addEventListener(AjaxIndicator.event, this.indicate.bind(this));
		this.updateCounter();
	}

	/**
	 * @param {Event} event
	 */
	indicate(event) {
		this.increase();
		event.detail.xhr.addEventListener('loadend', this.decreaseCallback);
		if (this.capture) {
			event.stopPropagation();
		}
	}

	/**
	 *
	 * @param {XMLHttpRequest} xhr
	 */
	increase() {
		this.count++;
		for (let el of this.indicators)el.classList.add(AjaxIndicator.PROGRESS_CLASS);
		this.updateCounter();
	}

	decrease() {
		this.count--;
		if (!this.count) {
			for (let el of this.indicators) el.classList.remove(AjaxIndicator.PROGRESS_CLASS);
		}
		this.updateCounter();
	}

	updateCounter() {
		this.element.dataset.ajaxRequests = this.count;
		if (this.counter) {
			this.counter.innerHTML = String(this.count);
		}
	}
}

/**
 * Spouštění událostí 'nette-ajax' při spuštění ajaxového dotazu
 * Událost má parametr s vlastnostmi {Promise} promise, {XMLHttpRequest} xhr, {HTMLElement} element
 * @property {Naja} naja
 */
class IndicatorExtension {

	static selector = `[${AjaxIndicator.DATA}]:not([${AjaxIndicator.DATA}=off])`;

	constructor(naja) {
		this.naja = naja;
		naja.addEventListener('load', this.load.bind(this));
		naja.addEventListener('start', this.start.bind(this));
		naja.addEventListener('before', this.before.bind(this));
		//naja.addEventListener('after', this.after.bind(this));
		naja.addEventListener('interaction', this.interaction.bind(this));
	}

	load() {
		for (let el of document.querySelectorAll(IndicatorExtension.selector)) el.indicator || (el.indicator = new AjaxIndicator(el));
	}

	/**
	 * @param {Promise} request
	 * @param {XMLHttpRequest} xhr
	 */
	start({request, xhr}) {
		if (xhr.element) {
			xhr.element.dispatchEvent(new CustomEvent(AjaxIndicator.event, {
				detail: {promise: request, xhr: xhr},
				cancelable: true,
				bubbles: true
			}));
		}
	}

	/**
	 * @param {XMLHttpRequest} xhr
	 * @param {Object} options
	 */
	before({xhr, options}) {
		if (options && options.element && options.indicator === true) {
			xhr.element = options.element;
		}
	}


	/**
	 * @param {Element} element
	 * @param {Object} options
	 */
	interaction({element, options}) {
		options.indicator = element.getAttribute(AjaxIndicator.DATA) !== 'off';
		options.element = element;
	}

}

//AjaxIndicator.install();
Naja.registerExtension(IndicatorExtension);

export {AjaxIndicator, IndicatorExtension};
