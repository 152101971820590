
window.cl = console.log;
window.tryCall = (method)=> {
	return (...args)=> {
		try {
			return method(...args);
		} catch (e) {
			console.log(e);
		}
	}
};

import 'object-fit-polyfill';
import './js/app.ajax';
import './js/ajax.indicator';
import './js/destroy';

