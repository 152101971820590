/**
 * @param {Element} node
 */
function destroy(node) {
	if (node instanceof HTMLElement) {
		for (let childNode of node.childNodes) {
			destroy(childNode);
		}
		const event = new CustomEvent('destroy');
		node.dispatchEvent(event);
	}
}

/**
 * @param {MutationRecord[]} mutationsList
 */
function observe(mutationsList) {
	for (let mutation of mutationsList) {
		for (let removedNode of mutation.removedNodes) {
			destroy(removedNode);
		}
	}
}
if(window.MutationObserver){
	const observer = new MutationObserver(observe);
	observer.observe(document.body, {childList: true,subtree: true});
}else{
	document.body.addEventListener('DOMNodeRemoved', function (e) {
		destroy(e.target);
	}, true);
}
