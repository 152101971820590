import Naja from 'naja/src/Naja';
import AbortExtension from 'naja/src/extensions/AbortExtension';
import UniqueExtension from 'naja/src/extensions/UniqueExtension';
import HistoryHandler from 'naja/src/core/HistoryHandler';

class MyHistoryHandler extends HistoryHandler{
    buildState(href, uiCache) {
        const state = super.buildState(href,uiCache);
        if (uiCache) state.scrollY = window.scrollY;
        return state;
    }
    handlePopState(e) {
        if ( ! e.state) return;
        super.handlePopState(e);
        if (e.state.ui && e.state.ui.scrollY) {
            window.scrollY= e.state.ui.scrollY;
        }
    }
}

const naja = new Naja(null, null, null, null, MyHistoryHandler, null);
naja.registerExtension(AbortExtension);
naja.registerExtension(UniqueExtension);

export default naja;
